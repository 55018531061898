<template>
	<v-container fluid>
		<StoryComponent
			v-if="exerciseID && formationID && storyID"
			:idFormation="formationID"
			:idExercice="exerciseID"
			:idStory="storyID"
		/>
	</v-container>
</template>

<script>
import StoryComponent from '@/components/story/Index.vue';

export default {
	name: 'Story',
	components: {
		StoryComponent,
	},
	data() {
		return {
			exerciseID: null,
			formationID: null,
			storyID: null,
		};
	},
	created() {
		// Guard make sure have exerciseID && formationID && storyID before render Story component
		const exerciseID = this.$route.params.idExercice || null;
		const formationID = this.$route.params.idFormation || null;
		const storyID = this.$route.params.idStory || null;
		if (exerciseID == null || exerciseID == 'null' || exerciseID == 'undefined') {
			this.$router.push({ name: 'Dashboard' });
			return;
		}
		if (formationID == null || formationID == 'null' || formationID == 'undefined') {
			this.$router.push({ name: 'Dashboard' });
			return;
		}
		if (storyID == null || storyID == 'null' || storyID == 'undefined') {
			this.$router.push({ name: 'Dashboard' });
			return;
		}

		this.exerciseID = exerciseID;
		this.formationID = formationID;
		this.storyID = storyID;
	},
};
</script>
